.kbn-chip{
    position: relative;
    padding: .4em .8em;
    background: var(--background);
    border-radius: 1em;
    // display: flex;
    &.is--success{
        background: rgba($color: $success, $alpha: .2);
    }
    &.is--danger{
        background: rgba($color: $danger, $alpha: .2);
    }
    &.is--warning{
        background: rgba($color: $warning, $alpha: .2);
    }
}
.kbn-tag{
    position: relative;
    padding: .2em .4em;
    background: var(--background_tint);
    border-radius: .2em;
    font-size: .9em;
    // display: flex;
    &.is--success{
        background: rgba($color: $success, $alpha: .2);
    }
    &.is--danger{
        background: rgba($color: $danger, $alpha: .2);
    }
    &.is--warning{
        background: rgba($color: $warning, $alpha: .2);
    }
    &.is--info{
        background: rgba($color: $info, $alpha: .2);
    }
}

.bt-card{
    position: relative;
    width: 100%;
    max-width: 17em;
    height: 27em;
    overflow: hidden;
    border-radius: .8em;
    background: var(--background);
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    border: .08em solid var(--color_rgba);
    display: flex;
    flex-direction: column;
    transition: .3s ease;
    &:hover{
        box-shadow: 0 15px 15px rgba($color: #000000, $alpha: .025);
    }
    &__container{
        position: relative;
        width: 100%;
        padding: .8em;
        background: var(--background);
        display: flex;
        flex-direction: column;
        row-gap: 2em;
    }
}

.bt-card-image{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @extend %flex-center;
    background: var(--background_tint);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}
.bt-card-info{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
    strong{
        font-family: var(--font_title);
        font-size: 1.1em;
    }
    span{
        font-size: .8em;
        opacity: .8;
    }
}
.bt-card-price{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
    strong{
        font-family: var(--font_title);
        font-size: 1.2em;
    }
    span{
        font-size: .8em;
        opacity: .8;
    }
    p{
        text-decoration: underline;
        color: var(--primary);
        font-size: .9em;
    }
}

.bt-ad{
    position: relative;
    width: 100%;
    max-width: 17em;
    height: 27em;
    overflow: hidden;
    border-radius: .8em;
    background: var(--background);
    overflow: hidden;
    display: flex;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom: 1em;
        left: 1em;
        background: var(--background);
        color: var(--color);
        padding: .5em 1em;
        border-radius: 1em;
        font-size: .8em;
    }
}

.bt-account-command{
    position: relative;
    width: 100%;
    padding: .8em;
    border-radius: .8em;
    background: var(--background);
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    border: .08em solid var(--color_rgba);
    display: flex;
    flex-direction: column;
    transition: .3s ease;
    font-size: .9em;
    &:hover{
        background-color: var(--primary_rgba);
        border-color: var(--primary);
    }
    &__top{
      h2{
        font-size: 1.1em;
      }
    }
}

.bt-cart-product{
    position: relative;
    width: 100%;
    padding: .8em;
    border-radius: .8em;
    background: var(--background);
    box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
    border: .08em solid var(--color_rgba);
    display: flex;
    column-gap: .8em;
    transition: .3s ease;
    font-size: .9em;
    &.is--selected{
        background-color: var(--primary_rgba);
        border-color: var(--primary);
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        gap: 1em;
    }
    &__contain{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .1em;
    }
    &__image{
        position: relative;
        width: 8em;
        height: 5em;
        overflow: hidden;
        border-radius: .1em;
        background: var(--background_tint);

        img{
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__action{
        position: relative;
        button{
            position: relative;
            width: 2.4em;
            height: 2.4em;
            @extend %flex-center;
            background: rgba($color: $danger, $alpha: 1);
            border-radius: .8em;
            color: #fff;
        }
    }
}

.bt-cart-product-box{
    position: relative;
    width: 2em;
    height: 2em;
    border-radius: .4em;
    border: .15em solid var(--color_rgba);
    padding: .2em;
    &.is--selected{
        border-color: var(--primary);
        .bt-cart-product-box__container{
            width: 100%;
            height: 100%;
            background: var(--primary);
            border-radius: .4em;
        }
    }
}

.bt-cart-product-price{
    position: relative;
    width: 100%;
    &__qte{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1em;
        flex-wrap: wrap;
        margin-top: .3em;
        button{
          position: relative;
          width: 2.5em;
          height: 2.5em;
          background: var(--background_tint);
          border-radius: 50%;
          border: .04em solid var(--color_rgba);
          @extend %flex-center;
          transition: .4s ease;
          &.is--disable{
            pointer-events: none;
            opacity: .4;
          }
        }
    }
}