// $barracuda_family: "Barracuda", sans-serif;
$barracuda_family: "Poppins", sans-serif;

$poppin_family: "Poppins", sans-serif;

:root {
  --font: #{$poppin_family};
  --font_title: #{$barracuda_family};
}

[data-font='default'] {
  --font: #{$poppin_family};
  --font_title: #{$barracuda_family};
}
