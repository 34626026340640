.kbn-textfield{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.2em;
    transition: all 0.3s ease;
    &.ev-focused{
      .kbn-textfield-label__text{
        font-weight: bold;
      }
    }
    &.st-error{
      .kbn-textfield-label__text{
        color: $danger;
        font-weight: bold;
      }
      .kbn-textfield__field{
        background: rgba($color: $danger, $alpha: 0.05);
        border: 1px solid $danger;
      }
    }

    &__options{
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(4em);
      width: 100%;
      background: var(--background-tint);
      border-radius: .8em;
      display: flex;
      flex-direction: column;
      row-gap: 0.4em;
      padding: 0.4em;
      color: var(--text-color);
      max-height: 14em;
      overflow-y: auto;
      z-index: 9;
    }
    &__option{
      position: relative;
      background: var(--background_tint);
      padding: 0.4em;
      font-size: 1em;
      border-radius: 0.8em;
      transition: 0.3s ease;
      &.is--selected{
        border: 1px solid var(--primary_rgba);
        background: var(--primary_rgba);
      }
    }
    label{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 0.25em;
      font-size: 1em;
      .kbn-textfield-label__text{
        font-weight: 500;
      }
    }
    .kbn-textfield-tooltip{
      position: relative;
      width: 1.2em;
      height: 1.2em;
      &:hover{
        .kbn-textfield-tooltip__text{
          display: flex;
        }
      }
      svg{
        width: 1.2em;
        height: 1.2em;
      }
      &__text{
        position: absolute;
        width: 11.25em;
        padding: 0.45em;
        font-size: 0.625em;
        display: none;
        flex-direction: column;
        background: var(--color);
        color: var(--background);
        row-gap: 0.1em;
        border-radius: .4em;
        top: 0;
        left: 0;
        transform: translateY(1.8em);
        z-index: 4;
      }
    }
    &__optionnal{
      position: relative;
      font-size: 0.625em;
      padding: 0.3em 0.4em;
      background: var(--background_tint);
      border-radius: 1em;
    }
    &.is--password{
      .kbn-textfield__field{
        input{
          width: calc(100% - 2.4em);
        }
      }
    }
    &.is--unmodifiable{
      pointer-events: none;
      opacity: .8;
    }
    &__field{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 0.2em;
      border-radius: .4em;
      background: transparent;
      border: .02em solid var(--color_tint);
      padding: 0.2em 0.4em;
      min-height: 2.75em;
      &.is--unmodifiable{
        border: none;
        background: var(--color_rgba);
        border-radius: .4em;
      }
      input, textarea, select{
        position: relative;
        width: 100%;
        background: none;
        color: inherit;
        font-size: 1em;
        outline: none;
        border: none;
        height: 100%;
      }
      textarea{
        resize: vertical;
        min-height: 2.75em;
        height: 13em;
        max-height: 18em;
      }
    }
    &__btn{
      min-width: 2.5em;
      min-height: 2.5em;
      @extend %flex-center;
      font-size: 1em;
  
    }
    .kbn-textfield-message{
      font-size: 1em;
      font-weight: 600;
      color: $danger;
    }
}
.kbn-selectfield{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.2em;
  transition: all 0.3s ease;
  &.ev-focused{
    .kbn-selectfield-label__text{
      font-weight: bold;
    }
  }
  &.st-error{
    .kbn-selectfield-label__text{
      color: $danger;
      font-weight: bold;
    }
    .kbn-selectfield-field{
      background: rgba($color: $danger, $alpha: 0.05);
      border: 1px solid $danger;
    }
  }
  label{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    font-size: 1em;
    .kbn-selectfield-label__text{
      font-weight: 500;
    }
  }
  .kbn-selectfield-tooltip{
    position: relative;
    width: 1.2em;
    height: 1.2em;
    &:hover{
      .kbn-selectfield-tooltip__text{
        display: flex;
      }
    }
    svg{
      width: 1.2em;
      height: 1.2em;
    }
    &__text{
      position: absolute;
      width: 11.25em;
      padding: 0.45em;
      font-size: 0.625em;
      display: none;
      flex-direction: column;
      background: var(--color);
      color: var(--background);
      row-gap: 0.1em;
      border-radius: .4em;
      top: 0;
      left: 0;
      transform: translateY(1.8em);
      z-index: 4;
    }
  }
  &__optionnal{
    position: relative;
    font-size: 0.625em;
    padding: 0.3em 0.4em;
    background: var(--background_tint);
    border-radius: 1em;
  }

  .kbn-selectfield-message{
    font-size: 1em;
    font-weight: 600;
    color: $danger;
  }
}
.kbn-selectfield-field{
  position: relative;
  width: 100%;
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.2em;
    border-radius: .4em;
    background: transparent;
    border: .02em solid var(--color_tint);
    padding: 0.2em 0.4em;
    min-height: 2.75em;
  }
}
.kbn-selectfield-field-options{
  position: absolute;
  // bottom: 0;
  // left: 0;
  transform: translateY(.5em);
  width: 100%;
  max-width: 30em;
  max-height: 20em;
  overflow-y: auto;
  padding: .5em;
  border-radius: .4em;
  background: var(--background_tint);
  border: .02em solid var(--color_tint);
  display: none;
  flex-direction: column;
  row-gap: .5em;
  z-index: 9;
  &.is--active{
    display: flex;
  }
  &__search{
    position: relative;
    width: 100%;
    input{
      position: relative;
      width: 100%;
      background: var(--background);
      padding: 0.6em 0.8em;
      min-height: 3em;
      color: var(--color);
      outline: none;
      border: none;
      border-radius: .4em;
    }
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
  }
}
.kbn-selectfield-field-option{
  position: relative;
  width: 100%;
  padding: .8em .4em;
  height: 2.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--background);
  border-radius: .4em;
  transition: .3s ease;
  &.is--active{
    position: relative;
    background: var(--primary_rgba);
    .kbn-selectfield-field-option-text i{
      color: var(--primary);
    }
  }
}
.kbn-selectfield-field-option-text{
  position: relative;
  width: calc(100% - 3em);
  display: flex;
  align-items: center;
  column-gap: .3em;
  i{
    font-size: 1.3em;
  }
}
.kbn-selectfield-field-option-delete{
  position: relative;
  width: 2.7em;
  height: 2.7em;
  @extend %flex-center;
  color: $danger;
  i{
    font-size: 1.3em;
  }
}

.kbn-multiselectfield{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.2em;
  transition: all 0.3s ease;
  &.ev-focused{
    .kbn-multiselectfield-label__text{
      font-weight: bold;
    }
  }
  &.st-error{
    .kbn-multiselectfield-label__text{
      color: $danger;
      font-weight: bold;
    }
    .kbn-multiselectfield-field{
      background: rgba($color: $danger, $alpha: 0.05);
      border: 1px solid $danger;
    }
  }
  label{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    font-size: 1em;
    .kbn-multiselectfield-label__text{
      font-weight: 500;
    }
  }
  .kbn-multiselectfield-tooltip{
    position: relative;
    width: 1.2em;
    height: 1.2em;
    &:hover{
      .kbn-multiselectfield-tooltip__text{
        display: flex;
      }
    }
    svg{
      width: 1.2em;
      height: 1.2em;
    }
    &__text{
      position: absolute;
      width: 11.25em;
      padding: 0.45em;
      font-size: 0.625em;
      display: none;
      flex-direction: column;
      background: var(--color);
      color: var(--background);
      row-gap: 0.1em;
      border-radius: .4em;
      top: 0;
      left: 0;
      transform: translateY(1.8em);
      z-index: 4;
    }
  }
  &__optionnal{
    position: relative;
    font-size: 0.625em;
    padding: 0.3em 0.4em;
    background: var(--background_tint);
    border-radius: 1em;
  }

  .kbn-multiselectfield-message{
    font-size: 1em;
    font-weight: 600;
    color: $danger;
  }
}
.kbn-multiselectfield-field{
  position: relative;
  width: 100%;
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.2em;
    border-radius: .4em;
    background: transparent;
    border: .02em solid var(--color_tint);
    padding: 0.2em 0.4em;
    height: auto;
    min-height: 2.75em;
  }
  &__grid{
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
  }
}
.kbn-multiselectfield-field-options{
  position: absolute;
  transform: translateY(.5em);
  width: 100%;
  max-width: 35em;
  max-height: 20em;
  overflow-y: auto;
  padding: .5em;
  border-radius: .4em;
  background: var(--background_tint);
  border: .02em solid var(--color_tint);
  display: none;
  flex-direction: column;
  row-gap: .5em;
  z-index: 9;
  &.is--active{
    display: flex;
  }
  &__search{
    position: relative;
    width: 100%;
    input{
      position: relative;
      width: 100%;
      background: var(--background);
      padding: 0.6em 0.8em;
      min-height: 3em;
      color: var(--color);
      outline: none;
      border: none;
      border-radius: .4em;
    }
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
  }
}
.kbn-multiselectfield-field-option{
  position: relative;
  width: 100%;
  padding: .8em .4em;
  height: 2.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--background);
  border-radius: .4em;
  transition: .3s ease;
  &.is--active{
    position: relative;
    background: var(--primary_rgba);
    .kbn-multiselectfield-field-option-text i{
      color: var(--primary);
    }
  }
}
.kbn-multiselectfield-field-option-text{
  position: relative;
  width: calc(100% - 3em);
  display: flex;
  align-items: center;
  column-gap: .3em;
  i{
    font-size: 1.3em;
  }
}
.kbn-multiselectfield-field-option-delete{
  position: relative;
  width: 2.7em;
  height: 2.7em;
  @extend %flex-center;
  color: $danger;
  i{
    font-size: 1.3em;
  }
}
.kbn-multiselectfield-field-item{
  span{
    display: block;
    padding: .4em .6em;
    background: var(--primary_rgba);
    border-radius: .4em;
  }
}

.kbn-filefield{
  position: relative;
  width: 100%;
  height: 22em;
  background: var(--background_tint);
  border-radius: .8em;
  input{
    display: none;
  }
  label{
    position: relative;
    width: 100%;
    height: 100%;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .8em;
    border: 1px dashed var(--color_tint);
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__label{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background_rgba);

    @extend %flex-center;
    flex-direction: column;
    row-gap: .8em;
    padding: .8em;
    opacity: 0;
    svg{
      width: 3.5em;
      height: 3.5em;
    }
    i{
      font-size: 2em;
    }
    transition: 0.25s ease;
    &:hover{
      opacity: 1;
    }
    &.force--open{
      background: var(--background);
      color: var(--color);
      i{
        color: var(--primary);
      }
      opacity: 1;
    }
  }
}
.kbn-specialdatefield{
  position: relative;
  width: 100%;
  &__header{
    position: relative;
    width: 100%;
  }
  &__container{
    position: relative;
    width: 100%;
  }
  &__field{
    position: relative;
    width: 100%;
  }
  &__actions{
    position: relative;
    display: flex;
    column-gap: .5em;
    button{
      position: relative;
      padding: .4em .8em;
      background: var(--background);
      border-radius: .4em;
      &.active{
        background: var(--primary_rgba);
      }
    }
  }
}


.kbn-filefield{
  position: relative;
  width: 100%;
  height: 22em;
  background: var(--background_tint);
  border-radius: var(--border_md);
  input{
    display: none;
  }
  label{
    position: relative;
    width: 100%;
    height: 100%;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .7em;
    border: 1px dashed var(--color_tint);
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    border-radius: var(--border_md);
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__label{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background_rgba);

    @extend %flex-center;
    flex-direction: column;
    row-gap: .8em;
    padding: .8em;
    opacity: 0;
    svg{
      width: 3.5em;
      height: 3.5em;
    }
    i{
      font-size: 2em;
    }
    transition: 0.25s ease;
    &:hover{
      opacity: 1;
    }
    &.force--open{
      background: var(--background);
      color: var(--color);
      i{
        color: var(--primary);
      }
      opacity: 1;
    }
  }
}
.kbn-specialdatefield{
  position: relative;
  width: 100%;
  &__header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: .4em;
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    gap: .4em;
  }
  &__field{
    position: relative;
    width: 100%;
  }
  &__actions{
    position: relative;
    display: flex;
    column-gap: .5em;
    button{
      position: relative;
      padding: .4em .8em;
      background: var(--background);
      border-radius: .4em;
      &.active{
        background: var(--primary_rgba);
      }
    }
  }
}