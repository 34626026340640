$default_primary: #EB4612;
$default_secondary: #3105E2;

$palette2_primary: #5b0ecf;
$palette2_secondary: #bd4e04;

$palette3_primary: rgb(220, 1, 100);
$palette3_secondary: rgb(33, 44, 241);

$palette4_primary: rgb(3, 156, 105);
$palette4_secondary: rgb(161, 117, 13);

$palette5_primary: rgb(1, 58, 246);
$palette5_secondary: rgb(215, 21, 205);

$background_light: #fff;
$background_dark: #2A2A2A;

$background_tint_light: #f8f7f7;
$background_tint_dark: #121212;

$color_dark: #fff;
$color_light: #140d35;

$color_tint_dark: #e6e6e6;
$color_tint_light: #3d3964;

$success: #00C880;
$danger: #E34E60;
$warning: #FFC700;
$info: #789efb;

$default_primary_rgba: rgba($color: $default_primary, $alpha: 0.1);
$default_secondary_rgba: rgba($color: $default_secondary, $alpha: 0.1);

$palette2_primary_rgba: rgba($color: $palette2_primary, $alpha: 0.3);
$palette2_secondary_rgba: rgba($color: $palette2_secondary, $alpha: 0.3);

$palette3_primary_rgba: rgba($color: $palette3_primary, $alpha: 0.3);
$palette3_secondary_rgba: rgba($color: $palette3_secondary, $alpha: 0.3);

$palette4_primary_rgba: rgba($color: $palette4_primary, $alpha: 0.3);
$palette4_secondary_rgba: rgba($color: $palette4_secondary, $alpha: 0.3);

$palette5_primary_rgba: rgba($color: $palette5_primary, $alpha: 0.3);
$palette5_secondary_rgba: rgba($color: $palette5_secondary, $alpha: 0.3);


$background_rgba_light: rgba($color: $background_light, $alpha: 0.2);
$background_rgba_dark: rgba($color: $background_dark, $alpha: 0.3);

$color_rgba_light: rgba($color: $color_light, $alpha: 0.1);
$color_rgba_dark: rgba($color: $color_dark, $alpha: 0.1);


:root {
  --background: #{$background_light};
  --background_tint: #{$background_tint_light};
  --color: #{$color_light};
  --color_tint: #{$color_tint_light};
  --color_rgba: #{$color_rgba_light};
  --background_rgba: #{$background_rgba_light};
}
[data-theme='dark'] {

    --background: #{$background_dark};
    --background_tint: #{$background_tint_dark};
    --color: #{$color_dark};
    --color_tint: #{$color_tint_dark};
  
    --color_rgba: #{$color_rgba_dark};
    --background_rgba: #{$background_rgba_dark};
}

[data-palette='default'] {
    --primary: #{$default_primary};
    --primary_rgba: #{$default_primary_rgba};
    --secondary: #{$default_secondary};
    --secondary_rgba: #{$default_secondary_rgba};

    --button: #{$default_primary};
    --button_text_color: #{$color_dark};
}
[data-theme='dark'] [data-palette='default'] {
    --primary: #{$default_secondary};
    --primary_rgba: #{$default_secondary_rgba};
    --secondary: #{$default_primary};
    --secondary_rgba: #{$default_primary_rgba};
    --button: #{$default_secondary};
    --button_text_color: #{$color_dark};
}

[data-palette='palette2'] {
    --primary: #{$palette2_primary};
    --primary_rgba: #{$palette2_primary_rgba};
    --secondary: #{$palette2_secondary};
    --secondary_rgba: #{$palette2_secondary_rgba};

    --button: #{$palette2_primary};
    --button_text_color: #{$color_dark};
}
[data-theme='dark'] [data-palette='palette2'] {
    --primary: #{$palette2_secondary};
    --primary_rgba: #{$palette2_secondary_rgba};
    --secondary: #{$palette2_primary};
    --secondary_rgba: #{$palette2_primary_rgba};
    --button: #{$palette2_secondary};
    --button_text_color: #{$color_dark};
}

[data-palette='palette3'] {
    --primary: #{$palette3_primary};
    --primary_rgba: #{$palette3_primary_rgba};
    --secondary: #{$palette3_secondary};
    --secondary_rgba: #{$palette3_secondary_rgba};

    --button: #{$palette3_primary};
    --button_text_color: #{$color_dark};
}
[data-theme='dark'] [data-palette='palette3'] {
    --primary: #{$palette3_secondary};
    --primary_rgba: #{$palette3_secondary_rgba};
    --secondary: #{$palette3_primary};
    --secondary_rgba: #{$palette3_primary_rgba};
    --button: #{$palette3_secondary};
    --button_text_color: #{$color_dark};
}

[data-palette='palette4'] {
    --primary: #{$palette4_primary};
    --primary_rgba: #{$palette4_primary_rgba};
    --secondary: #{$palette4_secondary};
    --secondary_rgba: #{$palette4_secondary_rgba};

    --button: #{$palette4_primary};
    --button_text_color: #{$color_dark};
}
[data-theme='dark'] [data-palette='palette4'] {
    --primary: #{$palette4_secondary};
    --primary_rgba: #{$palette4_secondary_rgba};
    --secondary: #{$palette4_primary};
    --secondary_rgba: #{$palette4_primary_rgba};
    --button: #{$palette4_secondary};
    --button_text_color: #{$color_dark};
}

[data-palette='palette5'] {
    --primary: #{$palette5_primary};
    --primary_rgba: #{$palette5_primary_rgba};
    --secondary: #{$palette5_secondary};
    --secondary_rgba: #{$palette5_secondary_rgba};

    --button: #{$palette5_primary};
    --button_text_color: #{$color_dark};
}
[data-theme='dark'] [data-palette='palette5'] {
    --primary: #{$palette5_secondary};
    --primary_rgba: #{$palette5_secondary_rgba};
    --secondary: #{$palette5_primary};
    --secondary_rgba: #{$palette5_primary_rgba};
    --button: #{$palette5_secondary};
    --button_text_color: #{$color_dark};
}