.bt-arianne{
    position: relative;
    display: flex;
    column-gap: .3em;
    opacity: .7;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: .8em;
    .active{
      font-weight: bold;
    }
    a{
      &:hover{
          text-decoration: underline;
      }
    }
}
.bt-page{
  position: relative;
  width: 100%;
  padding: .8em;
  max-width: 90em;
  margin: auto;
}
.bt-page-title{
  margin-bottom: 2em;
  h2{
    font-size: 2.3em;
    font-family: var(--font_title);
  }
}
.bt-home-banner{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.bt-home-banner-slider{
  position: relative;
  width: 100%;
  max-width: 60em;
  overflow: hidden;
  height: 35em;
  border-radius: 1.2em;
}
.bt-home-banner-side{
  position: relative;
  width: 100%;
  max-width: 27em;
  overflow: hidden;
  height: 35em;
  border-radius: 1.2em;
  img{
    @extend %image-center;
  }
  &__container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--secondary));
    color: #fff;
    display: flex;
    align-items: flex-end;
    padding: 1em 1em;
    h2{
      position: relative;
      width: calc(100% - 3em);
      font-family: var(--font_title);
      font-size: 2.5em;
      text-transform: uppercase;
      // font-weight: 400;
    }
  }
  &__logo{
    position: absolute;
    bottom: .5em;
    right: 1em;
    svg{
      width: auto;
      height: 3em;
    }
  }
}
.bt-home-banner-slide{
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 35em;
  img{
    @extend %image-center;
  }
  &__container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--secondary));
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 2em;
    flex-direction: column;
    row-gap: 1em;
    h2{
      position: relative;
      width: calc(100% - 3em);
      font-family: var(--font_title);
      font-size: 2.5em;
      text-transform: uppercase;
      // font-weight: 400;
    }
  }
  &__logo{
    position: absolute;
    bottom: .5em;
    right: 1em;
    svg{
      width: auto;
      height: 3em;
    }
  }
}


.bt-arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3em;
  height: 3em;
  background: var(--color);
  color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
  &#next{
      right: .5em;
  }
  &#prev{
      left: .5em;
  }
}

.bt-home-products{
  position: relative;
  width: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__header{
    position: relative;
    width: 100%;
    h2{
      font-size: 1.3em;
      font-family: var(--font_title);
    }
  }
  &__slider{
    position: relative;
    width: 100%;

  }
  &__slide{
    position: relative;
    padding: 0 1em;
    padding-left: 0;
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    gap: .8em;
    flex-wrap: wrap;
    // justify-content: center;
  }
}

.bt-home-categories{
  position: relative;
  width: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__header{
    position: relative;
    width: 100%;
    h2{
      font-size: 1.3em;
      font-family: var(--font_title);
    }
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    gap: .8em;
    flex-wrap: wrap;
  }
}
.bt-home-category{
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 25em;
  border-radius: 1.2em;
  &.is--two{
    position: relative;
    width: calc(50% - .8em);
  }
  &.is--three{
    position: relative;
    width: calc(100% / 3 - .8em);
  }
  img{
    @extend %image-center;
  }
  &__container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--secondary));
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 2em;
    flex-direction: column;
    row-gap: 1em;
    h2{
      position: relative;
      width: calc(100% - 3em);
      font-family: var(--font_title);
      font-size: 1.8em;
      text-transform: uppercase;
      // font-weight: 400;
    }
  }
  &__logo{
    position: absolute;
    bottom: .5em;
    right: 1em;
    svg{
      width: auto;
      height: 3em;
    }
  }
}

.bt-home-products-more{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center;
  opacity: .6;
  align-items: center;
  font-size: .9em;
  margin: 2em 0;
  span{
    white-space: nowrap;
  }

}

.bt-home-products-more-line{
  position: relative;
  width: 100%;
  height: 0.06em;
  background: currentColor;
}

.bt-product-banner{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  &__side{
    position: relative;
    width: 100%;
    max-width: 27em;
    height: 45em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  &__ads{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: .8em;
    background: var(--background);
    overflow: hidden;
    display: flex;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom: 1em;
        left: 1em;
        background: var(--background);
        color: var(--color);
        padding: .5em 1em;
        border-radius: 1em;
        font-size: .8em;
    }
  }
}
.bt-product-banner-info{
  border-radius: 1.2em;
  background: var(--background);
  border: .02em solid var(--color_rgba);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 1em;
  &__details{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
    h2{
      font-size: 2.1em;
      font-family: var(--font_title);
    }
    a{
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
      &:hover{
        color: var(--primary);
      }
    }
  }
  &__orders{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
  }
  &__price{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
    strong{
        font-family: var(--font_title);
        font-size: 2.4em;
    }
    span{
        font-size: .8em;
        opacity: .9;
    }
  }
  &__qte{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    button{
      position: relative;
      width: 2.5em;
      height: 2.5em;
      background: var(--background_tint);
      border-radius: 50%;
      border: .04em solid var(--color_rgba);
      @extend %flex-center;
      transition: .4s ease;
      &.is--disable{
        pointer-events: none;
        opacity: .4;
      }
    }
    input{
      max-width: 4em;
      text-align: center;
      color: inherit;
      background: none;
      outline: none;
      border: none;
    }
  }
  &__actions{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
    margin-top: 2em;
    button{
      justify-content: center;
      width: 100%;
      min-height: 3em;
    }
  }
  &__share{
    position: absolute;
    top: .5em;
    right: .5em;
    opacity: .8;
    &:hover{
      opacity: 1;
    }
    font-size: 1.2em;
  }
}
.bt-product-banner-info-avis{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  span{
    text-decoration: underline;
    opacity: .7;
  }
  &__stars{
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
  }
  &__star{
    position: relative;
    width: 1.2em;
    height: 1.2em;
    background: var(--color);
    clip-path: polygon(50% 0%, 63% 31%, 98% 35%, 73% 58%, 79% 91%, 50% 74%, 21% 91%, 27% 57%, 2% 35%, 36% 32%);
    opacity: .5;
    &.is--active{
      opacity: 1;
    }
  }
}
.bt-product-banner-images{
  position: relative;
  width: 100%;
  max-width: 60em;
  height: 45em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.bt-product-banner-image-main{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background);
  border-radius: 1.8em;
  img, video{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.bt-product-banner-image-list{
  position: relative;
  width: 100%;
  max-width: 35em;
  margin: auto;
}
.bt-product-banner-image-lil{
  position: relative;
  padding-right: .5em;
  button{
    position: relative;
    overflow: hidden;
    width: 6.5em;
    height: 6.5em;
    padding: .4em;
    border-radius: .8em;
    border: .2em solid transparent;
    &.is--active{
      border-color: var(--color);
    }
    img, video{
      @extend %image-center;
      border-radius: .4em;
    }
  }
}

.bt-product-navigation{
  position: relative;
  width: 100%;
  padding: .8em;
  border-radius: .8em;
  background: var(--background);
  margin: 1em 0;

  &__container{
    position: relative;
    width: 100%;
    display: none;
    padding: 1em 0;
    // min-height: 40em;
    max-height: 80svh;
    overflow-y: auto;
    &.is--active{
      display: block;
    }
  }
}
.bt-product-navigation-nav{
  position: relative;
  width: 100%;
  padding-bottom: .8em;
  border-bottom: .1em solid var(--color_rgba);
  display: flex;
  overflow-x: auto;
  gap: .8em;
  &__item{
      position: relative;
      padding: 0 .5em;
      white-space: nowrap;
      font-size: 1em;
      &.is--active{
          font-weight: bold;
          color: var(--primary);
          &::after{
              @include pseudo-el;
              width: 100%;
              height: 0.2em;
              background: var(--primary);
              bottom: -.8em;
              left: 0;
          }
      }
  }
}

.bt-account{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.bt-account-me{
  position: relative;
  width: 100%;
  max-width: 24em;
  padding: .8em;
  height: 25em;
  border-radius: 1.2em;
  background: var(--background);
  border: .02em solid var(--color_rgba);
  @extend %flex-center;
  flex-direction: column;
  row-gap: 1em;
  text-align: center;
  &__avatar{
    position: relative;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    @extend %flex-center;
    background: var(--background_tint);
    overflow: hidden;
    i{
      font-size: 2em
    }
    img{
      @extend %image-center;
    }
  }
  h2{
    font-size: 1.8em;
    font-family: var(--font_title);
    font-weight: bold;
  }
  span{
    opacity: .8;
    font-size: .9em;
  }
  &__actions{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: .8em;
  }
}
.bt-account-container{
  position: relative;
  width: 100%;
  max-width: calc(100% - 25em);
}
.bt-account-title{
  position: relative;
  width: 100%;
  margin-bottom: 2em;
  h1{
    font-size: 2.4em;
  }
  strong{
    font-size: 1.6em;
    font-family: var(--font_title);
  }
}
.bt-account-grid{
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 2em;
}

.bt-account-item{
  position: relative;
  width: 100%;
  max-width: 31.2em;
  border-radius: .8em;
  background: var(--background);
  border: .02em solid var(--color_rgba);
  padding: 1em;
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  justify-content: space-between;
  overflow: hidden;
  &.is--large{
    max-width: 100%;
  }
  &.is--special{
    border-color: var(--secondary);
    background: var(--secondary_rgba);
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .3em;
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .8em;
  }
  &__content{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span{
      font-size: .9em;
      opacity: .8;
    }
    strong{
      font-size: 1em;
    }
  }
}
.bt-account-item-orderS{
  position: relative;
  width: 100%;
  i{
    position: absolute;
    font-size: 9em;
    opacity: .1;
    top: -25%;
    left: -15%;
    pointer-events: none;
    color: var(--secondary);
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    font-size: .9em;
    
  }
  &__top{
    h2{
      font-size: 1.3em;
    }
  }
}

.bt-page-grid{
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}
.bt-page-item{
  position: relative;
  width: 100%;
  max-width: 28.8em;
}
.bt-page-filters{
  position: relative;
  width: 100%;
  margin-bottom: 1em;

.bt-arrow{
  
  &#next{
      right: -2em;
  }
  &#prev{
      left: -2em;
  }
}

}
.bt-page-filter{
  padding: .5em;
}
.bt-settings{
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 1em;
  &__container{
    position: relative;
    width: 100%;
    padding: 0 1em;
    h1{
      font-family: var(--font_title);
      font-size: 1.8em;
    }
  }
}

.bt-setting-menu{
  position: relative;
  width: 100%;
  max-width: 20em;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  border-radius: 1.2em;
  background: var(--background);
  border: .02em solid var(--color_rgba);
  padding: 1em;
  h2{
    font-family: var(--font_title);
    font-size: 1.2em;
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
  }
  &__item{
    position: relative;
    width: 100%;
    a{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      gap: .3em;
      padding: .8em 1em;
      background: var(--background_tint);
      border-radius: .8em;
      &:hover{
        background: var(--primary_rgba);
      }
      &.active{
        background: var(--button);
        color: var(--button_text_color);
      }
    }
  }
}

.kbn-page-settings{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  margin-top: 1em;
}
.kbn-page-setting{
  position: relative;
  width: 100%;
  background: var(--background);
  border-radius: .8em;
  border: .08em solid var(--color_rgba);
  padding: 1em;
  box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: .8em;
  h2{
    font-size: 1.2em;
    font-family: var(--font_title);
  }
  &__fonts{
      position: relative; 
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: .5em;
  }
  &__font{
    position: relative;
    width: 13em;
    padding: 1em;
    @extend %flex-center;
    flex-direction: column;
    row-gap: .5em;
    text-align: center;
    background: var(--background_tint);
    border-radius: .4em;
    strong{
        font: inherit;
        font-weight: bold;
        font-size: 1.4em;
    }
    span{
        font: inherit;
    }
    &.active{
        background: var(--button);
        color: var(--button_text_color);
    }
  }
  &__colors{
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: .5em;
  }
}
.kbn-page-setting-color{
  position: relative;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  padding: .4em;
  &.active{
      border: .2em solid var(--primary);
  }
  &__container{
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      transform: rotate(30deg);
      &.is--default{
          background: $default_primary;
          &::after{
              @include pseudo-el;
              width: 50%;
              height: 100%;
              top: 0;
              right: 0;
              background: $default_secondary;
          }
      }
      &.is--palette2{
          background: $palette2_primary;
          &::after{
              @include pseudo-el;
              width: 50%;
              height: 100%;
              top: 0;
              right: 0;
              background: $palette2_secondary;
          }
      }
      &.is--palette3{

          background: $palette3_primary;
          &::after{
              @include pseudo-el;
              width: 50%;
              height: 100%;
              top: 0;
              right: 0;
              background: $palette3_secondary;
          }
      }
      &.is--palette4{

          background: $palette4_primary;
          &::after{
              @include pseudo-el;
              width: 50%;
              height: 100%;
              top: 0;
              right: 0;
              background: $palette4_secondary;
          }
      }
      &.is--palette5{

          background: $palette5_primary;
          &::after{
              @include pseudo-el;
              width: 50%;
              height: 100%;
              top: 0;
              right: 0;
              background: $palette5_secondary;
          }
      }
  }
}
.kbn-page-setting-size{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: .5em;
  &__btn{
      width: 3em;
      height: 3em;
      border-radius: 50%;
      @extend %flex-center;
      background: var(--background_tint);
      i{
        font-size: 1em;
      }
    }
    &__slide{
      position: relative;
      width: calc(100% - 4em);
      input{
        width: 100%;
      }
    }
}

.kbn-page-setting-theme{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .4em;
  flex-wrap: wrap;
}
.kbn-page-setting-theme-switch{
  position: relative;
  width: 5.6em;
  height: 3em;
  background: var(--background_tint);
  padding: .2em;
  border-radius: 3em;
  border: .1em solid var(--color_rgba);
  &__content{
    position: relative;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--background);
    @extend %flex-center;
    font-size: 1em;
    transition: .3s ease;
    border: .1em solid var(--color_rgba);
    &.is--active{
      transform: translateX(100%);
      background: var(--secondary);
    }
  }
}
.kbn-page-setting-theme-default{
  position: relative;
  text-decoration: underline;
  span{
    opacity: .8;
  }
  &:hover{
    span{
      opacity: 1;
      font-weight: bold;
    }
  }
}

.bt-settings-box{
  position: relative;
  width: 100%;
  background: var(--background);
  border-radius: .8em;
  border: .08em solid var(--color_rgba);
  padding: 1em;
  box-shadow: 0 .5px .5px rgba($color: #000000, $alpha: .05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1em;
}

.bt-settings-box-fields{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: .8em;
}

.bt-page-cart{
  position: relative;
  width: 100%;
}
.bt-cart-title{
  position: relative;
  width: 100%;
  margin-bottom: 2em;
  h1{
    font-size: 2.4em;
  }
  strong{
    font-size: 1.6em;
    font-family: var(--font_title);
  }
}

.bt-cart-container{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
}
.bt-cart-recap{
  position: relative;
  width: 100%;
  max-width: 30em;
  &__container{
    position: sticky;
    top: 8em;
    width: 100%;
    padding: 1em;
    background: var(--background);
    border-radius: .8em;
    display: flex;
    flex-direction: column;
    row-gap: 1.2em;
    h2{
      font-size: 1.4em;
    }
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .6em;
  }
  &__item{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .4em;
    flex-wrap: wrap;
  }
  &__action{
    position: relative;
    width: 100%;
    button{
      width: 100%;
      justify-content: center;
    }
  }
}
.bt-cart-products{
  position: relative;
  width: 100%;
  padding: 1em;
  border-radius: .8em;
  background: var(--background);
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__header{
    position: relative;
    display: flex;
    gap: .8em;
    justify-content: flex-end;
    font-size: .8em;
    align-items: center;
    input{
      // border: none;
      max-width: 4em;
      text-align: right;
      background: transparent;
      color: inherit;
      border: .1em solid var(--color_rgba);
      padding: .2em;
      border-radius: .4em;
    }
  }
}
.bt-cart-products__grid{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: .7em;
}

.bt-cart-empty{
  position: relative;
  width: 100%;
  min-height: 60svh;
  @extend %flex-center;
  flex-direction: column;
  row-gap: 1em;
  text-align: center;
  svg{
    height: 30svh;
    width: auto;
  }
}
.bt-search-actions{
  position: relative;
  width: 100%;
  max-width: 60em;
  min-height: 3em;
  border-radius: 4em;
  border: .1em solid var(--color_rgba);
  background: var(--background_tint);
  padding: .5em 1em;
  display: flex;
  align-items: center;
  gap: .5em;
  input{
      position: relative;
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      outline: none;
      color: inherit;
  }
}

.bt-search-grid{
    position: relative;
    width: 100%;
    display: flex;
    gap: .5em;
    row-gap: .5em;
    flex-wrap: wrap;
    margin-top: 1em;
}
.bt-search-empty{
  position: relative;
  width: 100%;
  min-height: 60svh;
  @extend %flex-center;
  flex-direction: column;
  row-gap: 1em;
  text-align: center;
  svg{
    height: 30svh;
    width: auto;
  }
}


.kbn-page-parametre-avatar{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: .8em;
  margin-bottom: 2em;
  &__container{
      position: relative;
      width: 12em;
      height: 12em;
      input{
          display: none;
      }
  }
  &__actions{
      position: relative;
      width: 100%;
      max-width: 20em;
      display: flex;
      flex-direction: column;
      row-gap: .4em;
      button, label{
          // width: 100%;
          @extend %flex-center;
          height: 2.5em;
      }
  }
}
.kbn-page-parametre-avatar-image{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  @extend %flex-center;
  border-radius: 50%;
  cursor: pointer;
  i{
      font-size: 3em;
  }
  img{
      @extend %image-center;
  }
  background: var(--background_tint);

}
.bt-order{
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.bt-order-info{
  position: relative;
  width: 100%;
  max-width: 45em;
  font-size: 1.2em;
  background: var(--background);
  border-radius: .8em;
  padding: 1em .8em;
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    h2{
      font-size: 1.3em;
    }
    form{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      button{
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.bt-order-summary{
  position: relative;
  width: 100%;
  max-width: 30em;
  h2{
    font-size: 1.2em;
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .4em;
  }
}

.bt-order-summary-product{
  position: relative;
  width: 100%;
  display: flex;
  gap: .4em;
  background: var(--background);
  padding: 1em .8em;
  border-radius: .8em;
  &__image{
    width: 5em;
    height: 4em;
    overflow: hidden;
    border-radius: .6em;
    img{
      @extend %image-center
    }
  }
  p{
    font-size: .8em;
  }
}
.bt-order-summary-box{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background: var(--background);
  padding: 1em .8em;
  border-radius: .8em;
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5em;
    background: var(--background);
    border-radius: .8em;
  }
  &__item{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: .3em;
  }
}