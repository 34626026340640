.bt-loader{
    position: fixed;
    width: 100%;
    height: 100svh;
    background: var(--background);
    top: 0;
    left: 0;
    z-index: 999;
    @extend %flex-center;
    i{
        font-size: 3em;
    }
}
@keyframes animLoader{
    from {
        opacity: 0.2;
        transform: scale(.3);
    }
}
.bt-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}
.bt-header-top{
    position: relative;
    width: 100%;
    height: 4em;
    background: var(--background);
    border-bottom: .02em solid var(--color_tint);
    padding: .5em 2.4em;
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        gap: 1.8em;
        height: 100%;
    }
}
.bt-header-top-navigation{
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1.2em;
    &__logo{
        a{
            &:hover{
                color: var(--primary);
            }
        }
        svg{
            width: auto;
            height: 2.6em;
            path{
                fill: currentColor;
            }
        }
    }
    &__list{
        position: relative;
        display: flex;
        align-items: center;
        gap: .5em;
        li{
            position: relative;
            height: 100%;
            a{
                position: relative;
                padding: .5em .8em;
                height: 100%;
                display: flex;
                align-items: center;
                transition: .4s ease;
                font-size: .9em;
                white-space: nowrap;
                &:hover{
                    color: var(--primary);
                    opacity: .7;
                }
                &.active{
                    color: var(--primary);
                    font-weight: 600;
                    &::after{
                        @include pseudo-el;
                        top: 50%;
                        left: 0;
                        width: 2px;
                        height: 40%;
                        background: var(--secondary);
                        transform: translateY(-60%);
                        border-radius: 1px;
                    }
                }
            }
        }
    }
}
.bt-header-top-search{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4em;
    border: .1em solid var(--color_rgba);
    background: var(--background_tint);
    padding: .5em 1em;
    display: flex;
    align-items: center;
    gap: .5em;
    input{
        position: relative;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        color: inherit;
    }
}

.bt-header-top-auth{
    position: relative;
    width: auto;
    height: 100%;
    &__container{
        position: relative;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        gap: .5em;
    }
    &__group{
        position: relative;
    }
}
.bt-tooltip{
    position: absolute;
    bottom: -50%;
    padding: .2em .5em;
    font-size: .9em;
    background: var(--color);
    color: var(--background_tint);
    border-radius: .3em;
    display: none;
    white-space: nowrap;
    z-index: 9;
}
.bt-header-top-auth__btn{
    position: relative;
    min-width: 2.8em;
    min-height: 2.8em;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .8em;
    transition: .3s ease;
    
    &:hover{
        background: var(--primary_rgba);
        border-radius: 50%;
        .bt-tooltip{
            display: block;
        }
    }
    &.active{
        background: var(--primary);
        color: var(--button_text_color);
    }
    img{
        width: 2.8em;
        height: 2.8em;
        border-radius: .8em;
        object-fit: cover;
    }
}
.bt-header-top-auth-configs{
    position: absolute;
    padding: .5em;
    right: 0;
    background: var(--background_tint);
    border-radius: .3em;
    border: .1em solid var(--color_rgba);
    display: none;
    flex-direction: column;
    row-gap: .5em;
    z-index: 9;
    // transform: translate(.5em, 60%);
    font-size: .9em;
    &.is--open{
        display: flex;
    }
}

.bt-header-top-auth-config{
   
    position: relative;
    display: flex;
    align-items: center;
    gap: .3em;
    padding: .4em .8em;
    min-width: 13em;
    border-radius: .4em;
    &:hover{
        background: var(--primary_rgba);
    }
    &.active{
        background: var(--primary);
        color: var(--button_text_color);
    }
    &.danger{
        background: rgba($color: $danger, $alpha: .2);
        &:hover{
            background: rgba($color: $danger, $alpha: 1);
            color: #fff;
        }
    }
    &.is--special{
        border-radius: 0;
        border-bottom: .2em solid var(--background);
        padding-bottom: .6em;
        font-weight: 600;
        color: var(--primary);
        &:hover{
            background: var(--background_tint);
        }
    }
    
}

.bt-header-bottom{
    position: relative;
    width: 100%;
    height: 3em;
    background: var(--background_tint);
    border-bottom: .02em solid var(--color_tint);
    padding: .5em 2.4em;
    display: flex;
    align-items: center;
}

.bt-header-bottom-submenu{
    position: relative;
    width: auto;
    height: 100%;
    padding-right: 1em;
    border-right: .02em solid var(--color_tint);
    &__btn{
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        gap: .5em;
        border-radius: .4em;
        transition: .3s ease;
        padding: .6em .8em;
        white-space: nowrap;
        border: .09em solid var(--color_rgba);
        &:hover{
            background: var(--background);
        }
    }
    &__container{
        position: fixed;
        padding: .5em;
        left: 2em;
        width: 100%;
        max-width: 119em;
        height: 100svh;
        max-height: 35em;
        background: var(--background);
        // background: red;
        border-radius: .6em;
        border: .1em solid var(--color_rgba);
        display: none;
        gap: .5em;
        z-index: 9;
        font-size: .9em;
        transform: translate(.4em, .2em);
        &.is--open{
            display: flex;
        }
        h3{
            font-size: 1em;
        }
    }
    &__categories{
        position: relative;
        width: 100%;
        max-width: 15em;
        background: var(--background_tint);
        border-radius: .4em;
        padding: .5em 1em;
        display: flex;
        height: 100%;
        overflow-y: auto;
        flex-direction: column;
        row-gap: .8em;
        h3{
            border-bottom: .02em solid var(--background);
            padding-bottom: .3em;
        }
    }
    &__category{
        position: relative;
        display: flex;
        align-items: center;
        gap: .5em;
        border-radius: .4em;
        transition: .3s ease;
        padding: .6em .8em;
        &:hover{
            background: var(--background);
        }
        &.is--active{
            background: var(--background);
        }
    }
}

.bt-header-bottom-submenu-subcategories{
    position: relative;
    width: 100%;
    border-radius: .4em;
    padding: .5em 1em;
    display: flex;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    row-gap: 1em;
    &__empty{
        position: relative;
        width: 100%;
        height: 100%;
        @extend %flex-center;
        flex-direction: column;
        text-align: center;
        row-gap: .8em;
        svg{
            height: 15em;
            width: auto;
        }
    }
    &__header{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__list{
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__item{
        position: relative;
        display: flex;
        align-items: center;
        gap: .5em;
        border-radius: .4em;
        transition: .3s ease;
        padding: .6em .8em;
        width: calc(100% / 3 - .5em);
        &:hover{
            background: var(--background_tint);
        }
    }
}
.bt-header-bottom-navigation::-webkit-scrollbar {
    display: none; /* Webkit (Chrome, Safari) */
}

.bt-header-bottom-navigation{
    position: relative;
    width: 100%;
    padding: .5em 1em;
    display: flex;
    flex-wrap: nowrap;
    gap: .5em;
    font-size: .9em;
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    &__item{
        a, button{
            position: relative;
            display: flex;
            align-items: center;
            gap: .5em;
            border-radius: .4em;
            transition: .3s ease;
            padding: .3em .8em;
            white-space: nowrap;
            &.is--special{
                text-decoration: underline;
                font-weight: 600;
            }
            &.is--secondary{
                color: var(--secondary);
            }
            &.is--primary{
                color: var(--primary);
            }
            &:hover{
                background: var(--background);
            }
        }
        
    }
}

.bt-main{
    position: relative;
    width: 100%;
    padding-top: 7em;
    min-height: 30svh;
}

.bt-footer{
    position: relative;
    width: 100%;
    height: 45em;
    background: #2A1B4B;
    color: #fff;
    display: flex;
    flex-direction: column;
    &__container{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2em;
        @extend %flex-center;
        flex-direction: column;
        row-gap: 1em;
    }
    &__logo{
        svg{
            height: 9em;
            width: auto;
            opacity: .8;
        }
    }
    &__bottom{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: .8em;
        flex-wrap: wrap;
        align-items: center;
        background: #1D1233;
        padding: .8em 2.4em;
        font-size: .9em;
        svg{
            path{
                fill: currentColor;
            }
        }
    }
    &__copyright{
        font-weight: 600;
    }
    &__boxes{
        position: relative;
        width: 100%;
        max-width: 90em;
        display: flex;
        gap: 3em;
        justify-content: center;
        margin-top: 2em;

        font-size: .9em;
    }

    &__box{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.5em;
        h2{
            font-family: var(--font_title);
            font-size: 1.8em;
        }
        ul{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1em;
        }
        li{
            a{
                opacity: .8;
                transition: .5s ease;
                &:hover{
                    opacity: 1;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }
    &__form{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1em;
    }
    &__action{
        position: relative;
        width: 100%;
        button{
            position: relative;
            width: 100%;
            justify-content: center;
        }
    }
    &__field{
        position: relative;
        width: 100%;
        .kbn-textfield{
            label{
                display: none;
            }
            &__field{
                background: #fff;
                color: #000;
            }
        }
    }
}
.bt-footer-socials{
    display: flex;
    align-items: center;
    gap: 1em;
    &__list{
        position: relative;
        display: flex;
        gap: .5em;

    }
}
.bt-footer-social{
    position: relative;
    a{
        position: relative;
        width: 2em;
        height: 2em;
        background: #fff;
        color: #000;
        @extend %flex-center;
        border-radius: .4em;
    }
    svg{
        width: 1.2em;
        height: 1.2em;
    }
}


.bt-loader{
    position: relative;
    width: 100%;
    height: 100svh;
    background: var(--background_rgba);
    background: 30px;
    top: 0;
    left: 0;
    @extend %flex-center;
    i{
      color: var(--color_tint);
      font-size: 3em;
      opacity: .7;
    }
  
  }

.kbn-user-error{
    position: relative;
    width: 100%;
    height: 100svh;
    background: var(--background_rgba);
    background: 30px;
    top: 0;
    left: 0;
    @extend %flex-center;
    i{
      color: var(--color_tint);
      font-size: 1em;
    }
    text-align: center;
    svg{
        height: 50svh;
        width: auto;
    }
}

.bt-product-banner-image-main__content{
    position: relative;
    width: 100%;
    height: 40svh;
    overflow: hidden;
}