.kbn-page-list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    margin-top: 1em;
}
.kbn-page-list-row{
    position: relative;
    width: 100%;
    display: flex;
    border-bottom: .1em solid var(--color_rgba);
    &.is--header{
        text-transform: uppercase;
        // font-size: 1.2em;
    }
}
.kbn-page-list-col{
    position: relative;
    
    &.is--min{
        width: 5em;
        @extend %flex-center;
        font-weight: bold;
        padding: .5em .3em;

    }
    &.is--auto{
        width: 100%;
        // min-height: 4.5em;
        padding: .5em .3em;
        @extend %flex-vertical-center;
    }
    &.is--mid{
        position: relative;
        width: 12em;
        @extend %flex-center;
        gap: .5em;
        padding: .5em .3em;
    }

}
.kbn-page-list-btn{
    position: relative;
    width: 2.75em;
    height: 2.75em;
    @extend %flex-center;
    background: var(--background_tint);
    color: var(--color);
    border-radius: .4em;
    &.is--danger{
      color: var(--text-color);
      background: rgba($color: $danger, $alpha: 0.1);
    }
    &.is--success{
      color: var(--text-color);
      background: rgba($color: $success, $alpha: 0.1);
    }
    &.is--warning{
      background: rgba($color: $warning, $alpha: 0.1);
    }
    &.is--disable{
        background: var(--background_tint);
        // pointer-events: none;
        opacity: .8;
    }
    span{
        position: fixed;
        transform: translateY(1.4em);
        padding: .2em .5em;
        font-size: .9em;
        background: var(--color);
        color: var(--background);
        border-radius: .3em;
        display: none;
        white-space: nowrap;
        z-index: 6;
    }
    &:hover{
        span{
            display: block;
        }
    }
}
.kbn-page-list-body{
    position: relative;
    width: 100%;
}
.kbn-page-list-user{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: .5em;
    &__avatar{
        position: relative;
        width: 3em;
        height: 3em;
        border-radius: 50%;
        overflow: hidden;
        @extend %flex-center;
        background: var(--background_tint);
        img{
            @extend %image-center;
        }
        i{
            font-size: 1em;
        }
    }
    &__container{
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 4em);
        span{
            font-size: .6em;
            opacity: .6;
        }
    }
}