.bt-about-page{
    position: relative;
    width: 100%;
    padding: 1.5em;
}
.bt-banner{
    position: relative;
    width: 100%;
    height: 84svh;
    overflow: hidden;
    border-radius: 2em;
    &__image{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            @extend %image-center;
        }
    }
    &__container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: .8);
        color: #fff;
        @extend %flex-center;
        flex-direction: column;
        row-gap: 1em;
        padding: 1em;
        text-align: center;
        svg{
            width: 4em;
            height: auto;
        }
        h1{
            display: block;
            max-width: 25em;
            font-size: 3.2em;
        }
    }
}

.bt-about{
    position: relative;
    width: 100%;
    display: flex;
    gap: .5em;
    column-gap: 3em;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 2em;
    flex-wrap: wrap;
    &__container{
        position: relative;
        width: 100%;
        max-width: 38em;
        display: flex;
        flex-direction: column;
        row-gap: .8em;
        strong{
            font-size: 3em;
            color: var(--primary);
        }
        p{
            opacity: .9;
        }
    }
    &__images{
        position: relative;
        width: 100%;
        max-width: 33em;
        height: 45em;
    }
    &__image{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 2em;
        img{
            @extend %image-center;
        }
    }
    &__box{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        background: var(--background);
        border: .4em solid  var(--background_tint);
        width: 13em;
        height: 15em;
        border-radius: 2em;
        padding: 1em;
        @extend %flex-center;
        flex-direction: column;
        line-height: 2.2em;
        row-gap: .3em;
        h1{
            font-size: 4em;
            color: var(--primary);
        }
        span{
            font-size: .8em;
            opacity: .6;
        }
        p{
            font-size: 1.2em;
        }

    }
}

.bt-service{
    position: relative;
    width: 100%;
    @extend %flex-center;
    flex-direction: column;
    row-gap: 2em;
    margin: 2em 0;
    margin-bottom: 4em;
    &__header{
        position: relative;
        text-align: center;
        h1{
            font-size: 3em;
            color: var(--primary);
        }
        span{
            opacity: .9;
        }
    }
    &__grid{
        position: relative;
        width: 100%;
        @extend %flex-center;
        flex-wrap: wrap;
        gap: 1em;
    }
}

.bt-service-item{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 30em;   
    @extend %flex-center;
    flex-direction: column;
    row-gap: 1em;
    background: var(--background);

    svg{
        width: 6em;
        height: auto;
        color: var(--primary)
    }
    strong{
        font-size: 1.5em;
    }
    &::after{
        @include pseudo-el;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .5em;
        background: linear-gradient(45deg, var(--primary), var(--secondary), var(--primary), var(--secondary));
    }
    &:nth-child(2){
        svg{
            color: var(--secondary)
        }
    }
}

.bt-promote{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 2em;
    // flex-wrap: wrap;
    &__image{
        position: relative;
        width: 100%;
        height: 45em;   
        overflow: hidden;
        border-radius: 2em;
        img{
            @extend %image-center;
        }
    }
    &__text{
        position: relative;
        width: 100%;
        height: 45em;
        background: var(--background);
        border-radius: 2em;
        padding: 2em;
        @extend %flex-center;
        text-align: center;
        // 
        p{
            padding: .8em;
            font-size: 1.5em;
        }
    }
}