:root{
    font-size: 14px;
}
  
*,::after,::before{
    padding: 0;
    margin: 0;
    font-family: var(--font);
    box-sizing: border-box;
    outline: none;
}
::selection{
    background: var(--primary);
    color: var(--button_text_color);
}
html{
    width: 100%;
    height: 100%;
}
body{
    color: var(--color);
    background-color: var(--background);
}

.bt-app {
    color: var(--color);
    background-color: var(--background_tint);
    min-height: 100vh;
    transition: all .25s;
}
a{
    color: inherit;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}
button{
    border: none;
    cursor: pointer;
    outline: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font: inherit;
}
i, strong, span{
    color: inherit;
}
ul{
    list-style: none;
}
#fill_color{
    fill: currentColor;
}
#stroke_color{
    stroke: currentColor;
}
#primary{
    fill: var(--primary);
}

.bt-offline-alert{
    position: fixed;
    width: 100%;
    padding: 1.5em 1em;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--color);
    color: var(--background);
    z-index: 999;
    @extend %flex-center;
    flex-direction: column;
    row-gap: .5em;
    strong{
        font-size: 1.4em;
    }
}